import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { LuPackagePlus } from "react-icons/lu";
import { MdOutlineBloodtype } from "react-icons/md";
import { RiMentalHealthFill } from "react-icons/ri";
import { GiHeavyThornyTriskelion } from "react-icons/gi";
import { MdOutlineGppGood } from "react-icons/md";
import { CiLogin } from "react-icons/ci";
import { FaUserDoctor } from "react-icons/fa6";
import { CgProfile } from "react-icons/cg";

import { VscFeedback } from "react-icons/vsc";
const MobileView = ({
    isMenuOpen,
    toggleMenu,
    token,
    handleLogout,
    isProfileOpen,
    handleHome,
    popupSignin
}) => {
    // const [toggleSignin, setToggleSignin] = useState(false)
    // const popupSignin = () => {
    //     setToggleSignin(!toggleSignin);
    // };
    return (
        < div className={`lg:hidden fixed md:w-1/3 sm:w-2/3 w-2/3 inset-0 bg-white flex transition-transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'}`
        }>
            <div className="relative flex flex-col justify-start h-screen overflow-y-auto text-xl pe-8">
                <div className="absolute left-6 top-6">
                    <FontAwesomeIcon icon={faTimes} className="text-black text-2xl cursor-pointer" onClick={toggleMenu} />
                </div>

                {/* Sample Menu Items */}
                <div className="flex flex-col gap-4  flex-grow mt-32 ps-6">
                    <div>
                        <div>
                            {token ? (
                                <div
                                    onClick={handleHome}
                                    className="relative flex items-center"
                                >
                                    <div className='flex justify-center items-center gap-4'>
                                        <CgProfile className="text-xl cursor-pointer  " />
                                        <label className="text-xl  text-center">Home</label>
                                    </div>
                                    {isProfileOpen && (
                                        <div className="absolute top-7 left-1/2 transform -translate-x-1/2 bg-opacity-50 flex justify-center items-center z-50 transition-all ease-in-out duration-300">
                                            <div className="bg-gray-100 p-2 rounded-lg w-52 relative shadow-lg">
                                                <div className="space-y-0 mt-4">
                                                    <div onClick={toggleMenu}>
                                                        <Link to="/userprofile">
                                                            <h1 className="text-lg font-semibold">Dashboard</h1>
                                                        </Link>
                                                        <hr className="my-2 border-gray-400" />
                                                    </div>
                                                    <div onClick={toggleMenu}>
                                                        <Link to="/userorder">
                                                            <h2 className="text-lg font-semibold">My Sample Tracking</h2>

                                                        </Link>
                                                        <hr className="my-2 border-gray-400" />
                                                    </div>

                                                    <div onClick={toggleMenu}>
                                                        <button onClick={handleLogout} className="text-lg font-semibold">Logout</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <Link to="/loginform">
                                    <div className="text-black flex items-center gap-2" onClick={toggleMenu}>
                                        <CiLogin />
                                        <p className=''>SignIn</p>
                                    </div>
                                </Link>

                            )}
                        </div>
                        <div className="w-full h-px bg-gray-300 mt-4"></div>
                    </div>

                    <div>
                        <Link to="/healthrisknavbarlink" className="text-black flex items-center gap-2" onClick={toggleMenu}>
                            <GiHeavyThornyTriskelion />
                            <p>Health Risks</p>
                        </Link>
                        <div className="w-full h-px bg-gray-300 mt-4"></div>
                    </div>

                    {/* Additional links */}
                    <div>
                        <Link to="/health-conditions" className="text-black flex items-center gap-2" onClick={toggleMenu}>
                            <RiMentalHealthFill />
                            <p>Health Conditions</p>
                        </Link>
                        <div className="w-full h-px bg-gray-300 mt-4"></div>
                    </div>

                    <div>
                        <Link to="/bloodTest" className="text-black flex items-center gap-2" onClick={toggleMenu}>
                            <MdOutlineBloodtype />
                            <p>Blood Tests</p>
                        </Link>
                        <div className="w-full h-px bg-gray-300 mt-4"></div>
                    </div>

                    <div>
                        <Link to="/healthpackagecollection" className="text-black flex items-center gap-2" onClick={toggleMenu}>
                            <LuPackagePlus />
                            <p>Health Packages</p>
                        </Link>
                        <div className="w-full h-px bg-gray-300 mt-4"></div>
                    </div>
                    <div>
                        <Link to="/specialty" className="text-black flex items-center gap-2" onClick={toggleMenu}>
                            <MdOutlineGppGood />
                            <p>Specialty</p>
                        </Link>
                        <div className="w-full h-px bg-gray-300 mt-4"></div>
                    </div>

                    <div>
                        <Link to="/consult-doctor" className="text-black flex items-center gap-2" onClick={toggleMenu}>
                            <FaUserDoctor />
                            <p>Consult a Doctor</p>
                        </Link>
                        <div className="w-full h-px bg-gray-300 mt-4"></div>
                    </div>

                    <div>
                        <Link to="/help-feedback" className="text-black flex items-center gap-2" onClick={toggleMenu}>
                            <VscFeedback />
                            <p>Help & Feedback</p>
                        </Link>
                        <div className="w-full h-px bg-gray-300 mt-4"></div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default MobileView;
