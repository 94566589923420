import React, { useState } from 'react';

const Help_Feedback = () => {
    // State management for form inputs
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // You can send the form data to your backend here
        console.log({ name, email, message });

        setIsSubmitted(true); // Show success message
    };

    return (
        <div className="max-w-4xl mx-auto p-6">
            {/* Feedback Form Section */}
            <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-2xl font-semibold mb-4">Submit Your Feedback</h3>

                {isSubmitted ? (
                    <div className="bg-green-100 text-green-700 p-4 rounded-lg mb-6">
                        <p>Thank you for your feedback!</p>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="space-y-4">
                            {/* Name Input */}
                            <div>
                                <label htmlFor="name" className="block text-gray-700 font-medium">Your Name</label>
                                <input
                                    id="name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg"
                                    placeholder="Enter your name"
                                    required
                                />
                            </div>

                            {/* Email Input */}
                            <div>
                                <label htmlFor="email" className="block text-gray-700 font-medium">Your Email</label>
                                <input
                                    id="email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg"
                                    placeholder="Enter your email"
                                    required
                                />
                            </div>

                            {/* Message Input */}
                            <div>
                                <label htmlFor="message" className="block text-gray-700 font-medium">Your Message</label>
                                <textarea
                                    id="message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg"
                                    rows="4"
                                    placeholder="Write your message or feedback"
                                    required
                                />
                            </div>

                            {/* Submit Button */}
                            <div className="mt-4">
                                <button
                                    type="submit"
                                    className="w-full px-4 py-2 bg-purple-800 text-white rounded-lg hover:bg-purple-700 transition duration-300"
                                >
                                    Submit Feedback
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default Help_Feedback;
