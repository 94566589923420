import axios from 'axios';
import React, { useEffect, useState } from 'react';
import constantApi from '../../constantApi';
import Cookies from 'js-cookie';
import { FaCalendarAlt, FaDollarSign, FaHeartbeat } from 'react-icons/fa'; // Added icons
import Loader from '../Loader';
import { Link } from 'react-router-dom';

const MyAppointment = () => {
    const [appointmentData, setAppointmentData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const token = Cookies.get('token');
        axios
            .get(`${constantApi.baseUrl}user/consult`, {
                headers: {
                    Authorization: token,
                },
            })
            .then((response) => {
                setAppointmentData(response.data.data);
                setIsLoading(false)
            })
            .catch((err) => {
                console.log('error ', err);
            });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="bg-purple-100 min-h-screen flex items-center justify-center p-6">
            <div className="w-full max-w-3xl bg-white rounded-2xl shadow-2xl p-8">
                <h1 className="text-xl sm:text-xl md:text-xl lg:text-4xl font-bold text-center text-purple-700 mb-8">
                    My Appointments
                </h1>
                {isLoading ? (
                    <div className="flex justify-center items-center lg:h-80 md:h-60 sm:h-60 h-60 ">
                        <Loader />
                    </div>
                ) :
                    (<div>
                        {appointmentData && appointmentData.length > 0 ? (
                            <div className="">
                                {appointmentData.map((data, index) => (
                                    <div
                                        key={index}
                                        className="bg-gradient-to-r from-purple-400 to-purple-700 rounded-2xl shadow-xl p-6 space-y-6 text-white transform hover:scale-105 transition-all duration-300 my-4"
                                    >
                                        {/* Icons and Title */}
                                        <div className="flex items-center gap-4">
                                            <FaHeartbeat className="text-3xl" />
                                            <h2 className="text-2xl font-semibold">{data.speciality}</h2>
                                        </div>

                                        {/* Information Section */}
                                        <div className="space-y-4">
                                            <div className="flex justify-between items-center text-lg">
                                                <span className="font-semibold text-yellow-200">Consult Status:</span>
                                                <span>{data.consultstatus}</span>
                                            </div>

                                            <div className="flex justify-between items-center text-lg">
                                                <span className="font-semibold text-yellow-200">Status:</span>
                                                <span>{data.status}</span>
                                            </div>

                                            <div className="flex justify-between items-center text-lg">
                                                <span className="font-semibold text-yellow-200">Amount Paid:</span>
                                                <span>₹{data.amount}</span>
                                            </div>

                                            <div className="flex justify-between items-center text-lg">
                                                <span className="font-semibold text-yellow-200">Appointment Date:</span>
                                                <span>{new Date(data.createdAt).toLocaleDateString()}</span>
                                            </div>
                                        </div>

                                        {/* Icon for Date */}
                                        <div className="flex justify-between text-sm text-gray-300 mt-4">
                                            <FaCalendarAlt className="text-xl" />
                                            <span>{new Date(data.createdAt).toLocaleTimeString()}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-center text-lg text-gray-600 mt-6">
                                You have no appointments yet. <Link to="/consult-doctor"><span className='underline  decoration-purple-500 text-xl font-bold hover:scale-105 duration-500'>Book one now!</span></Link>
                            </p>
                        )}
                    </div>)}

            </div>
        </div>
    );
};

export default MyAppointment;
