import React, { useEffect } from "react";
import vallabhies from "../../Images/About/Vallabhies_diagnostic_center.jpeg"
import hospital from "../../Images/About/hospital-building.png"
import laboratory from "../../Images/About/laboratory.png"
import performance from "../../Images/About/performance.png"

const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="bg-white py-10 px-6 md:px-20 lg:px-40">
            <div className="grid md:grid-cols-2 items-center gap-8">
                {/* Text Section */}
                <div>
                    <h1 className="text-3xl font-bold text-purple-600 mb-6">About Us</h1>
                    <p className="text-gray-700 mb-4">
                        Vallabhies diagnostic is one of the leading and renowned
                        Indian diagnostics companies.
                    </p>
                    <p className="text-gray-700 mb-4">
                        The Company enjoys a loyal customer base, reflecting its strength as
                        a brand offering superior diagnostic tests and services.
                    </p>
                    <p className="text-gray-700 mb-4">
                        With its widespread operational network, Vallabhies diagnostic offers a
                        comprehensive range of clinical laboratory tests and profiles.
                        These tests and profiles are used for prediction, early detection,
                        diagnostic screening, confirmation and/or monitoring of the disease.
                    </p>
                    <p className="text-gray-700">
                        Vallabhies diagnostic plays a pivotal role in raising the bar of diagnostic  accuracy, technological equipment, customer experience and
                        research-driven empathetic service in the industry.
                    </p>
                </div>

                {/* Image Section */}
                <div className="flex justify-center">
                    <img
                        src={vallabhies}
                        alt="About Us Illustration"
                        className="w-80 h-80 rounded-md"
                    />
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-4">
                {/* Statistics Section */}
                <div className="flex flex-wrap justify-center md:justify-between items-center gap-8">
                    <div className="flex flex-col text-center items-center">
                        <img src={hospital} className="h-16 w-16 md:h-20 md:w-20" alt="Hospital" />
                        <p className="text-xl md:text-2xl font-bold">400+</p>
                        <p className="text-sm md:text-base">Collection Centers</p>
                    </div>
                    <div className="flex flex-col text-center items-center">
                        <img src={laboratory} className="h-16 w-16 md:h-20 md:w-20" alt="Laboratory" />
                        <p className="text-xl md:text-2xl font-bold">50+</p>
                        <p className="text-sm md:text-base">Laboratories</p>
                    </div>
                    <div className="flex flex-col text-center items-center">
                        <img src={performance} className="h-16 w-16 md:h-20 md:w-20" alt="Performance" />
                        <p className="text-xl md:text-2xl font-bold">100k+</p>
                        <p className="text-sm md:text-base">Tests Performed</p>
                    </div>
                </div>

                {/* Text Section */}
                <div>
                    <p className="text-gray-700 text-sm md:text-base mb-4">
                        The Company’s broad spectrum of services offer around 3,487 clinical laboratory tests and 530 profiles. Various test combinations, specific to a disease or disorder and wellness profiles used for health and fitness screening form a part of the Company’s service profile.
                    </p>
                    <p className="text-gray-700 text-sm md:text-base mb-4">
                        Vallabhies diagnostic has invested in a variety of specialized tests and also adopted several advanced tests and technologies introduced in the global market. As on date, the Company offers 2,799 specialized tests. One of the prime reasons behind the Company’s leading position in the diagnostics industry is its track record of introducing specialized tests.
                    </p>
                </div>
            </div>

        </div>
    );
};

export default AboutUs;
