import React, { useState, useEffect } from "react";
// import mumbai from "../Images/location_img/Mumbai.svg";
import lko from "../Images/location_img/lko.png";
import knp from "../Images/location_img/knp.png";
import gkp from "../Images/location_img/gkp.png";
import noida from "../Images/location_img/noida.png";
import agra from "../Images/location_img/agra.png";
import { useLocationContext } from "../context/LocationContext";
import { IoIosCloseCircleOutline } from "react-icons/io";
import cityData from "../components/in.json"


const HeaderLocation = () => {
    // Popular city data
    const citiesData = [
        { city: "Lucknow", image: lko },
        { city: "Kanpur", image: knp },
        { city: "Gorakhpur", image: gkp },
        { city: "Noida", image: noida },
        { city: "Agra", image: agra },
    ];
    const { selectedLocation, setSelectedLocation } = useLocationContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [add, setAdd] = useState("");
    const [cities, setCities] = useState([])

    // Cities list
    // const cities = [
    //     "Lucknow", "Kanpur", "Agra", "Varanasi", "Meerut", "Allahabad", "Ghaziabad",
    //     "Noida", "Bareilly", "Mathura", "Aligarh", "Jhansi", "Moradabad", "Firozabad",
    //     "Saharanpur", "Muzaffarnagar", "Rampur", "Etawah", "Bijnor", "Maharajganj",
    //     "Gorakhpur", "Sitapur", "Shahjahanpur", "Amethi", "Hapur",
    //     "New Delhi", "Dwarka", "Rohini", "Pitampura", "Janakpuri", "Karol Bagh",
    //     "Connaught Place", "Lajpat Nagar", "Saket", "Preet Vihar", "Vasant Kunj",
    //     "Greater Kailash", "Defence Colony", "Green Park", "Hauz Khas", "Shalimar Bagh",
    //     "Malviya Nagar", "Punjabi Bagh", "Civil Lines", "Chandni Chowk",
    //     "Mumbai", "Navi Mumbai", "Thane", "Andheri", "Borivali",
    // ];

    useEffect(() => {
        const value = cityData.map((x) => x.city
        )
        setCities(value)
    }, [])


    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    // Lock body scroll when modal is open and unlock when it is closed
    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isModalOpen]);

    // Automatically fetch user location
    useEffect(() => {
        navigator.geolocation.getCurrentPosition((pos) => {
            const { latitude, longitude } = pos.coords;
            const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
            fetch(url)
                .then((res) => res.json())
                .then((data) => setAdd(data.address));
        });
    }, []);

    // Filter cities based on search input
    const filteredCities = cities.filter((city) =>
        city.toLowerCase().includes(search.toLowerCase())
    );

    const handleCitySelect = (city) => {
        setSelectedLocation(city);
        closeModal();
    };

    const handleOutsideClick = (e) => {
        if (e.target.classList.contains("modal-overlay")) {
            closeModal();
        }
    };

    const handleClosePoup = () => {
        closeModal();
    }


    return (
        <div className="flex-grow relative">
            <div className="w-full relative bg-purple-300 px-2 lg:py-0 md:py-0 sm:py-0 py-0 rounded-s-sm">
                {/* Button Styled to Look Like Input */}
                <button
                    className="w-full text-black cursor-pointer bg-transparent py-2"
                    onClick={openModal}
                >
                    <span className="text-gray-600">
                        {selectedLocation || add.city || "City"}
                    </span>
                </button>
            </div>

            {/* Modal Popup */}
            {isModalOpen && (
                <div
                    className="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-40 pt-8 px-4 sm:px-8 md:px-12"
                    onClick={handleOutsideClick}
                >
                    <div className="bg-white p-4 sm:p-5 md:p-6 rounded-lg text-black w-full sm:w-3/4 md:w-2/3 lg:w-2/3 max-h-[80vh] overflow-y-auto">
                        <div className="flex justify-between items-center">
                            <h2 className="text-xl mb-2 font-bold">Choose your City</h2> <span><IoIosCloseCircleOutline className="text-2xl" onClick={handleClosePoup} />
                            </span>
                        </div>

                        <h2 className="text-xl mb-2">Most Popular</h2>

                        <div className="flex justify-between items-center flex-wrap   gap-4">
                            {citiesData.map((city, index) => (
                                <div
                                    onClick={() => handleCitySelect(city.city)}
                                    key={index}
                                    className="flex flex-col items-center cursor-pointer border rounded-md  lg:w-32 md:w-32 sm:w-28 w-28 p-2 shadow-md shadow-gray-400 hover:scale-105 duration-700 "
                                >
                                    <div>
                                        <img src={city.image} alt={city.city} className="rounded-md w-24 h-24 object-cover" />
                                    </div>
                                    <div className="py-2 px-4">{city.city}</div>
                                </div>
                            ))}
                        </div>

                        <div className="bg-gray-500 h-px w-full mt-6" />

                        <div className="mt-3 flex justify-between items-center flex-wrap sm:flex-nowrap">
                            <h1 className="font-bold">All Cities</h1>
                            <div className="my-4 w-full sm:w-auto">
                                <input
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder="Search cities"
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md"
                                />
                            </div>
                        </div>

                        <div className="bg-gray-300 h-px w-full my-2" />

                        {/* Grid of Cities */}
                        <div className="grid lg:grid-cols-3 md:grid-cols-2  sm:grid-cols-2 grid-cols-2  gap-2 max-h-48 overflow-y-auto">
                            {filteredCities.map((city, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleCitySelect(city)}
                                    className="py-2 px-4 hover:bg-gray-200 cursor-pointer"
                                >
                                    {city}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            )}
        </div>



    );
};

export default HeaderLocation;
