import React, { useEffect, useState } from 'react';
import constantApi from '../../constantApi';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';

const UserOrder = () => {
    const [orderDetail, setOrderDetail] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const token = Cookies.get("token");
        axios
            .get(`${constantApi.baseUrl}user/order/`, {
                headers: {
                    Authorization: token,
                },
            })
            .then((response) => {
                setOrderDetail(response.data.data);
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("Error:", err);
            });
    }, []);

    // Function to handle file download
    const downloadFile = (url) => {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.pdf"); // Replace with dynamic name if needed
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the DOM
    };

    // Handle row click navigation
    const handleRowClick = (orders) => {
        navigate(`/usertestDetail`, { state: orders });
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="p-6 bg-gray-50 min-h-screen">
            <h1 className="text-4xl font-extrabold text-gray-800 mb-6 text-center">
                Requested Test Dashboard
            </h1>
            {/* Requests Table */}
            {isLoading ? (
                <div className="flex justify-center items-center text-center lg:h-80 md:h-60 sm:h-60 h-60 ">
                    <Loader />
                </div>
            ) : (
                <div className="overflow-x-auto shadow-lg rounded-lg bg-white">
                    <table className="min-w-full border-collapse">
                        <thead className="bg-purple-600 text-white">
                            <tr>
                                <th className="px-6 py-3 text-left font-semibold">S.No</th>
                                <th className="px-6 py-3 text-left font-semibold">Order ID</th>
                                <th className="px-6 py-3 text-left font-semibold">Order Date</th>
                                <th className="px-6 py-3 text-left font-semibold">Name</th>
                                <th className="px-6 py-3 text-left font-semibold">Tests</th>
                                <th className="px-6 py-3 text-left font-semibold">Location</th>
                                <th className="px-6 py-3 text-left font-semibold">Status</th>
                            </tr>
                        </thead>

                        <tbody>

                            {orderDetail.map((orders, index) => (
                                <tr
                                    key={orders._id}
                                    className={`cursor-pointer ${index % 2 === 0 ? "bg-gray-100" : "bg-white"
                                        } hover:bg-gray-200`}
                                    onClick={(e) => {
                                        if (e.target.tagName !== "BUTTON") {
                                            handleRowClick(orders);
                                        }
                                    }}
                                >
                                    <td className="px-6 py-4 text-gray-800">{index + 1}</td>
                                    <td className="px-6 py-4 text-gray-800">{orders.uuid}</td>
                                    <td className="px-6 py-4 text-gray-800">{(orders.createdAt).split("T")[0]}</td>
                                    <td className="px-6 py-4 text-gray-800">{orders.user.name}</td>
                                    <td className="px-6 py-4 text-gray-800">{orders.product.name}</td>
                                    <td className="px-6 py-4 text-gray-800">{orders.address.city}</td>
                                    <td className="px-6 py-4 text-gray-800">
                                        {orders.orderstatus !== "completed" ? (
                                            orders.orderstatus
                                        ) : (
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent event bubbling
                                                    downloadFile(orders.testreport);
                                                }}
                                                className="bg-green-600 px-4 py-2 text-white font-bold rounded-lg"
                                            >
                                                Download
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>


                    </table>
                </div>
            )}
        </div>
    );
};

export default UserOrder;
