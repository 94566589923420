import React, { useState, useEffect } from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

const Popup = () => {
    const [visible, setVisible] = useState(false);
    const [name, setName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [checkbox, setCheckbox] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Show popup after 30 seconds
        const initialTimeout = setTimeout(() => {
            setVisible(true);
        }, 30000); // 30 seconds

        // Show popup every 3 minutes
        const recurringInterval = setInterval(() => {
            setVisible(true);
        }, 180000); // 3 minutes

        // Cleanup intervals on component unmount
        return () => {
            clearTimeout(initialTimeout);
            clearInterval(recurringInterval);
        };
    }, []);

    const handleClose = () => {
        setVisible(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent form from refreshing the page

        if (mobileNumber.length >= 10) {
            console.log('Name:', name);
            console.log('Mobile Number:', mobileNumber);
            setName('');
            setMobileNumber('');
            setVisible(false);
            setError(false);
        } else {
            setError(true);
        }
    };

    const handlePrivacy = () => {
        navigate("/privacy-policy");
        setVisible(false);
    };

    const handleTerms = () => {
        navigate("/termsandconditions");
        setVisible(false);
    };

    const handleCheckboxChange = (e) => {
        setCheckbox(e.target.checked);
    };

    if (!visible) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="relative bg-purple-500 p-6 rounded-lg shadow-lg max-w-xl w-full text-white">
                {/* Close button */}
                <button
                    onClick={handleClose}
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    aria-label="Close"
                >
                    <IoCloseSharp className="text-3xl bg-white" />
                </button>
                {/* Form */}
                <div className="w-full">
                    <p className="text-2xl font-semibold text-center py-1">Need help in booking test?</p>
                    <p className="text-center py-1">
                        Please share your details, our health advisor will call you
                    </p>
                    <p className="mt-2 text-center py-1">
                        or you can call us at{' '}
                        <span className="bg-yellow-500 px-2 py-1 rounded-md text-purple-800 font-bold">
                            <a href="tel:8595952501" className="font-bold">
                                8595952501
                            </a>
                        </span>
                    </p>
                </div>
                <form onSubmit={handleSubmit} className="space-y-4 mx-8">
                    <div>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            placeholder="Name"
                            onChange={(e) => setName(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 bg-purple-500 sm:text-sm text-white border-b-2 border-white focus:outline-none placeholder-white"
                            required
                        />
                    </div>
                    <div>
                        <input
                            type="tel"
                            id="mobileNumber"
                            value={mobileNumber}
                            placeholder="Mobile Number"
                            onChange={(e) => setMobileNumber(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 bg-purple-500 sm:text-sm border-b-2 border-white focus:outline-none placeholder-white"
                            required
                        />
                        {error && <span className="text-red-600">Incorrect Mobile Number</span>}
                    </div>
                    <div>
                        <div className="flex items-center mb-4">
                            <input
                                id="default-checkbox"
                                type="checkbox"
                                value=""
                                checked={checkbox}
                                onChange={handleCheckboxChange}
                                className="w-4 h-4 bg-gray-100"
                            />
                            <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium">
                                <p>
                                    I agree to Vallabhies Diagnostics &nbsp;
                                    <span
                                        onClick={handleTerms}
                                        className="text-yellow-500 cursor-pointer"
                                    >
                                        T&C &nbsp;
                                    </span>
                                    and &nbsp;
                                    <span
                                        onClick={handlePrivacy}
                                        className="text-yellow-500 cursor-pointer"
                                    >
                                        Privacy Policy
                                    </span>
                                </p>
                            </label>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className={`w-full py-2 px-4 bg-yellow-500 text-black font-medium rounded ${checkbox ? 'hover:bg-yellow-600' : ''
                            } transition`}
                        disabled={!checkbox}
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Popup;
