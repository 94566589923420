import React from 'react';

const ToastNotification = ({ message }) => {
    if (!message) return null; // Don't render if there's no message

    return (
        <div className="fixed top-0 left-0 right-0 mt-4 p-4 bg-green-500 text-white text-center rounded-lg shadow-lg z-50">
            {message}
        </div>
    );
};

export default ToastNotification;
