
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Swal from 'sweetalert2';

import { FaUserDoctor } from "react-icons/fa6";
import doctor from "../../Images/doctor.png"
import gp from "../../Images/consultDoctor/gp.png"
import gyno from "../../Images/consultDoctor/uterus.png"
import skin from "../../Images/consultDoctor/glowing-skin.png"
import bone from "../../Images/consultDoctor/medical.png"
import consultation from "../../Images/consultDoctor/consultation.png"
import chest from "../../Images/consultDoctor/air.png"
import child from "../../Images/consultDoctor/newborn.png"
import diab from "../../Images/consultDoctor/diab.png"
import axios from "axios";
import constantApi from "../../constantApi";
import { Link, Navigate, useNavigate, useNavigation } from "react-router-dom";
const ChooseDoctor = () => {
    const navigate = useNavigate()
    const [speciality, setSpeciality] = useState(null);
    const [loading, setLoading] = useState(false); // State for loader

    const totalamount = 199
    const token = Cookies.get("token")

    const specialities = [
        { id: 1, name: "General Physician", icon: gp },
        { id: 2, name: "Gynaecologist", icon: gyno },
        { id: 3, name: "Skin & Hair Specialist", icon: skin },
        { id: 4, name: "24x7 Doctor Assistance", icon: consultation },
        { id: 5, name: "Bone & Joint Specialist", icon: bone },
        { id: 6, name: "Chest Physician", icon: chest },
        { id: 7, name: "Child Specialist", icon: child },
        { id: 8, name: "Diabetes Specialist", icon: diab },
    ];

    const handleProceed = () => {
        setLoading(true); // Start the loader
        axios.post(`${constantApi.baseUrl}user/consult`,
            { speciality, totalamount },
            {
                headers: {
                    "Authorization": token
                }
            }
        )
            .then((response) => {

                console.log("response is ", response);
                if (speciality) {
                    setLoading(true)
                    Swal.fire({
                        title: 'Appointment Scheduled',
                        text: 'You will receive a notification soon!',
                        icon: 'success',
                        timer: 2000,
                    }).then(
                        navigate("/")
                    )
                } else {
                    alert("please choose Doctor")
                    setLoading(false)
                }

            }).catch((err) => {
                console.log("error is", err)
            }
            )
    }

    return (
        <div className="min-h-screen bg-gray-50 p-6">
            {/* Header */}
            <div className="max-w-4xl mx-auto">
                <div className="flex justify-between items-center">
                    <div className=" mb-6 pb-4">
                        <h2 className="text-2xl font-semibold text-gray-800">Select your speciality</h2>
                        <p className="text-sm text-gray-500 mt-2">
                            Scroll and select the speciality of your choice
                        </p>
                    </div>
                    <div>
                        <img src={doctor} className="h-20 w-20 rounded-full"></img>
                    </div>
                </div>


                {/* Speciality List */}
                <div className="space-y-4 overflow-y-auto  h-52">
                    {specialities.map((doctor) => (
                        <div
                            key={doctor.id}
                            className={`flex items-center justify-between  cursor-pointer lg:w-[50%] md:w-[70%] sm:w-[95%] w-[95%] border rounded-md  px-4 py-1 ${speciality === doctor.name
                                ? "border-red-500 bg-red-50"
                                : "border-gray-200"
                                }`}
                            onClick={() => setSpeciality(doctor.name)}
                        >
                            <div className="flex items-center space-x-4 ">
                                <div className="text-2xl">
                                    <img src={doctor.icon} alt="ashish" className="h-12 w-12"></img>
                                </div>
                                <p className="text-gray-800 font-medium">{doctor.name}</p>
                            </div>
                        </div>
                    ))}
                </div>


                {/* Help Section */}
                <div className="mt-6 bg-orange-50 p-4 rounded-md flex flex-col md:flex-row items-start md:items-center justify-between space-y-4 md:space-y-0">
                    <div className="flex items-start md:items-center space-x-4">
                        <div className="text-4xl">🤔</div>
                        <div>
                            <p className="text-orange-600 font-semibold text-base md:text-lg">
                                Not sure about which specialty to choose?
                            </p>
                            <p className="text-sm md:text-base text-gray-500">
                                Get a consultation from our general physician.
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-center items-center">
                        <button
                            onClick={() => setSpeciality("General Physician")}
                            className="text-red-500 font-medium md:text-sm lg:text-base">
                            Consult a general physician
                        </button>
                    </div>
                </div>

                {/* Navigation Buttons */}
                <div className="flex justify-between items-center mt-8">
                    <Link to="/consult-doctor">
                        <button className="text-red-500 font-medium flex items-center">
                            <span className="mr-2 text-lg">←</span> Back
                        </button>
                    </Link>

                    <button
                        onClick={handleProceed}
                        disabled={loading} // Disable button when loading
                        className={` text-white font-semibold py-2 px-6 rounded-lg ${loading ? "bg-red-500 cursor-not-allowed" : "bg-red-500 hover:bg-red-600"
                            } focus:outline-none`}
                    >
                        {loading ? "Proceeding..." : "Proceed"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChooseDoctor;
