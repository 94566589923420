import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useCart } from '../../context/CartContext';
import axios from 'axios';
import constantApi from '../../constantApi';
import Loader from '../Loader';
import { useLocationContext } from '../../context/LocationContext';

function HealthConditionCollection() {
    const { name } = useParams();
    const [tests, setTests] = useState([]);
    const [filteredTests, setFilteredTests] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { addToCart, cartItemIds } = useCart();
    const navigate = useNavigate();
    const { selectedLocation } = useLocationContext();

    // Fetch health condition data
    useEffect(() => {
        axios
            .get(`${constantApi.baseUrl}user/product/${name}`)
            .then((response) => {
                setTests(response.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching test data', error);
            });
    }, [name]);

    // Filter tests by location
    useEffect(() => {
        if (selectedLocation) {
            const filtered = tests.filter((test) =>
                test.location.some((loc) =>
                    loc.toLowerCase().includes(selectedLocation.toLowerCase())
                )
            );
            setFilteredTests(filtered);
        } else {
            setFilteredTests(tests);
        }
    }, [selectedLocation, tests]);

    const handleViewDetails = (test) => {
        navigate(`/${name.toLowerCase().replace(/\s+/g, '-')}/${test._id}`, { state: { test } });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="pb-12 mt-8">
            <section className="px-4 sm:px-8">
                <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center sm:text-left">
                    {name}
                </h2>
                {isLoading ? (
                    <div className="flex justify-center items-center lg:h-80 md:h-60 sm:h-60 h-60">
                        <Loader />
                    </div>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                        {filteredTests.length > 0 ? (
                            filteredTests.map((test) => (
                                <div key={test._id} className="w-full">
                                    <div
                                        onClick={() => handleViewDetails(test)}
                                        className="border border-gray-300 rounded-lg p-6 shadow-md hover:shadow-lg transition-all duration-200 ease-in-out cursor-pointer flex flex-col items-center justify-center space-y-4"
                                    >
                                        <h3 className="text-lg font-semibold text-gray-700 text-center">
                                            {test.name}
                                        </h3>
                                        <p className="text-lg font-semibold text-black-600">
                                            Rs. {test.amount}
                                        </p>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                addToCart(test);
                                            }}
                                            className={`text-white py-2 px-6 text-sm rounded-md w-full ${cartItemIds.includes(test._id)
                                                ? "bg-green-600"
                                                : "bg-[#522f7c]"
                                                }`}
                                        >
                                            {cartItemIds.includes(test._id)
                                                ? "Added to Cart"
                                                : "Add to Cart"}
                                        </button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-center text-gray-500 col-span-full">
                                No tests found for the selected location.
                            </p>
                        )}
                    </div>
                )}
            </section>
        </div>
    );
}

export default HealthConditionCollection;
