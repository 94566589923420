import React from "react";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from "react-router-dom";
import HeaderLocation from "./HeaderLocation";
import logo from "../Images/logo.png";
import Search from "./Search";

const MainHeader = () => {
    const openWhatsApp = () => {
        const phoneNumber = "8595952501";
        const message = "Hi! I have a query about your services.";
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
        <div>
            <div className="bg-[#522f7c] text-white flex h-20 items-center justify-between px-4 py-2  lg:flex header">
                <Link to="/">
                    <div className="flex justify-center items-center">
                        <div className="font-bold lg:text-4xl md:text-lg sm:text-md text-md">
                            <img src={logo} className="object-cover lg:h-28 md:h-20 sm:h-20 h-20" alt="Logo" />
                        </div>
                        <div>
                            <h1 className="font-bold">Vallabhies Diagnostic</h1>
                        </div>
                    </div>
                </Link>
                <div className="flex justify-center items-center">
                    <div className="bg-[#FF5E5E]">
                        <HeaderLocation />
                    </div>
                    <div className="flex-grow max-w-md relative hidden lg:flex header">
                        <Search />
                    </div>
                </div>

                <div className="flex items-center space-x-2 hidden lg:flex header">
                    <i className="fab fa-whatsapp text-xl text-green-500"></i>
                    <button onClick={openWhatsApp} className="font-bold">
                        8595952501
                    </button>
                </div>
                <div className="flex items-center space-x-2 hidden lg:flex header">
                    <Link to="/consult-doctor" className="text-black">

                        <button className="font-bold bg-[#FF5E5E] text-white px-4 py-2 border rounded-md hover:bg-red-600">
                            Consult a doctor
                        </button>
                    </Link>
                </div>
                <div className="flex items-center space-x-2 hidden lg:flex header">
                    <i className="fas fa-phone-alt text-xl"></i>
                    <a href="tel:8595952501" className="font-bold">
                        8595952501
                    </a>
                </div>
            </div>
        </div>
    );
};

export default MainHeader;
