import React from "react";
import { useNavigate } from "react-router-dom";
import bloodTest from "../../Images/HealthConditionImage/img/blood-test.png"
import allergyTest from "../../Images/HealthConditionImage/img/allergy-test.png"
import anemia from "../../Images/HealthConditionImage/img/anemia.png"
import cancer from "../../Images/HealthConditionImage/img/cancer.png"
import arthritis from "../../Images/HealthConditionImage/img/arthritis.png"
import diabetes from "../../Images/HealthConditionImage/img/diabetes.png"

import fever from "../../Images/HealthConditionImage/img/fever.png"
import Hepatitis from "../../Images/HealthConditionImage/img/Hepatitis.png"
import Hormones from "../../Images/HealthConditionImage/img/Hormones.png"
import Hypertension from "../../Images/HealthConditionImage/img/Hypertension.png"
import Obesity from "../../Images/HealthConditionImage/img/Obesity.png"
import pregnancy from "../../Images/HealthConditionImage/img/pregnancy.png"
import std from "../../Images/HealthConditionImage/img/std.png"
import vitamins from "../../Images/HealthConditionImage/img/vitamins.png"

function HealthCondition() {
  const navigate = useNavigate();

  const tests = [
    { id: 1, name: "Blood Alchohal Test", img: bloodTest, testCount: 14, route: "/Blood-alchohal-test" },
    { id: 2, name: "Allergy", img: allergyTest, testCount: 10, route: "/Allergy-test" },
    { id: 3, name: "Anemia", img: anemia, testCount: 8, route: "/Anemia-test" },
    { id: 4, name: "Arthritis", img: arthritis, testCount: 10, route: "/Arthritis-test" },
    { id: 5, name: "Cancer", img: cancer, testCount: 13, route: "/Cancer-test" },
    { id: 6, name: "Diabetes", img: diabetes, testCount: 13, route: "/Diabetes-test" },
    { id: 7, name: "Fever", img: fever, testCount: 13, route: "/Fever-test" },
    { id: 8, name: "Hepatitis", img: Hepatitis, testCount: 13, route: "/Hepatitis-test" },
    { id: 9, name: "Hormones", img: Hormones, testCount: 13, route: "/Hormones-test" },
    { id: 10, name: "Hypertension", img: Hypertension, testCount: 13, route: "/Hypertension-test" },
    { id: 11, name: "Obesity", img: Obesity, testCount: 13, route: "/Obesity-test" },
    { id: 12, name: "Pregnancy", img: pregnancy, testCount: 13, route: "/Pregnancy-test" },
    { id: 13, name: "STD (Sexually Transmitted Diseases)", img: std, testCount: 13, route: "/STD-test" },
    { id: 14, name: "Vitamins", img: vitamins, testCount: 13, route: "/Vitamins-test" },
  ];

  const handleClick = (testName) => {
    navigate(`/test/${testName}`); // Navigate to the dynamic test route
  };

  return (
    <div className="px-4 sm:px-8 lg:px-16 py-8 sm:py-12 bg-gray-100">
      <div className="h-auto bg-white px-4 py-3 rounded-xl shadow-sm">
        <p className="text-xl sm:text-2xl font-semibold text-gray-700">Health Condition</p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
        {tests.map((test) => (
          <div
            key={test.id}
            className="p-4 bg-white shadow-md rounded-lg flex items-center cursor-pointer transition-all duration-200 hover:shadow-lg"
            onClick={() => handleClick(test.name)}
          >
            <div className="h-24 w-24 flex justify-center items-center border border-purple-500 rounded-lg sm:h-28 sm:w-28">
              {/* If image is not available, show a default icon */}
              {test.img ? (
                <img src={test.img} alt={test.name} className="w-full h-full object-contain p-4" />
              ) : (
                <span role="img" aria-label="icon" className="text-lg sm:text-2xl">
                  🌟
                </span>
              )}
            </div>
            <div className="ml-4">
              <p className="text-sm sm:text-lg font-semibold text-gray-800">{test.name}</p>
              <p className="text-gray-500 text-xs sm:text-sm">Tests: {test.testCount}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HealthCondition;
