import React from 'react';
import doctor from "../../Images/home_img/doctor.png";

const Specialty = () => {
    // Define an array of doctor data
    const doctors = [
        {
            name: "ABC",
            position: "Consultant Pathologist, Global Reference Lab",
            qualification: "MD Pathology",
            department: "Chemistry",
            expertise: "Specialised in Chemistry, Transplant Immunology (HLA), Neurochemistry, Haematology"
        },
        {
            name: "DEF",
            position: "Consultant Cardiologist, Heart Care Clinic",
            qualification: "MD Cardiology",
            department: "Cardiology",
            expertise: "Specialised in Cardiology, Heart Diseases, Electrophysiology"
        },
        {
            name: "GHI",
            position: "Consultant Neurologist, Neuro Health Center",
            qualification: "MD Neurology",
            department: "Neurology",
            expertise: "Specialised in Neurology, Brain Disorders, Epilepsy, Stroke"
        },
        // Add more doctor objects as needed
    ];

    return (
        <div className="m-4">
            {/* Grid layout with 3 columns */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {doctors.map((doctorData, index) => (
                    <div key={index} className="flex justify-center items-center bg-white p-6 rounded-lg shadow-lg">
                        <div className="flex items-center justify-center mr-6">
                            <img
                                src={doctor}
                                alt="Image of doctor"
                                className="w-40 h-40 object-cover rounded-full" // Adjust the size of the image
                            />
                        </div>
                        <div className="flex flex-col gap-4 w-full">
                            <div>
                                <h1 className="text-xl font-semibold">{doctorData.name}</h1>
                                <p className="text-sm">{doctorData.position}</p>
                                <p className="text-sm">{doctorData.qualification}</p>
                            </div>
                            <hr className="my-4 border-t-2 border-gray-300" />
                            <div>
                                <h2 className="text-xl font-semibold">Department: {doctorData.department}</h2>
                                <p className="text-sm">Expertise</p>
                                <p className="text-sm">{doctorData.expertise}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Specialty;
