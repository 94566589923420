import React, { createContext, useContext, useState } from "react";

// Create the Search Context
const SearchContext = createContext();

// Custom hook to use the Search Context
export const useSearch = () => useContext(SearchContext);

export const SearchProvider = ({ children }) => {
    const [searchLocation, setSearchLocation] = useState(""); // Global search input
    const [filteredTests, setFilteredTests] = useState([]); // Global filtered tests

    const value = {
        searchLocation,
        setSearchLocation,
        filteredTests,
        setFilteredTests,
    };

    return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};
