import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const UserTestDetail = () => {
    const location = useLocation();
    const userTestInfo = location.state;
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="min-h-screen bg-gradient-to-br from-purple-100 via-blue-100 to-green-50 p-8">
            <header className="text-center mb-10">
                <h1 className="lg:text-3xl md:text-xl sm:text-lg text-lg font-extrabold text-gray-900">{userTestInfo.product.name}'s Information</h1>
                {/* <p className="text-lg text-gray-600 mt-2">Detailed information about the userTestInfo's test and status</p> */}
            </header>

            <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6 space-y-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <p className="text-lg"><strong>ID:</strong> {userTestInfo.uuid}</p>
                    <p className="text-lg"><strong>Test:</strong> {userTestInfo.product.name}</p>
                    <p className="text-lg"><strong>Amount:</strong> ₹{userTestInfo.product.amount}</p>
                    {/* <p className="text-lg"><strong>Status:</strong> <span className={`font-semibold ${status === "Completed" ? "text-green-600" : "text-yellow-500"}`}>{status}</span></p> */}
                </div>
                <div>
                    <p className="text-lg"><strong>Description:</strong> {userTestInfo.product.description}</p>
                </div>
            </div>
        </div>
    )
}

export default UserTestDetail
