import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useCart } from "../../context/CartContext";
import { MdDeleteOutline } from "react-icons/md";
import { FaShoppingCart } from "react-icons/fa";
import axios from "axios";
import constantApi from "../../constantApi";
import { Link } from "react-router-dom";
import cartv from "../../Images/cartv.mp4"

const Cart = ({ isCartOpen, setIsCartOpen }) => {
    const [totalItems, setTotalItems] = useState();
    const [totalPrice, setTotalPrice] = useState(0);
    const [cartItemIds, setCartItemIds] = useState([]);
    const [itemIds, setItemIds] = useState([]);

    const minimize = () => {
        setIsCartOpen(false);
    };

    const { cart, removeFromCart, clearCart } = useCart();

    useEffect(() => {
        setTotalItems(cart.length);
        setItemIds(cart.map((item) => item._id));
        setCartItemIds(itemIds);

        const total = cart.reduce((accumulator, item) => {
            return accumulator + item.amount;
        }, 0);
        setTotalPrice(total);
    }, [cart]);

    const handlePlaceOrder = () => {
        axios
            .post(`${constantApi.baseUrl}user/`, itemIds)
            .then((response) => {
                console.log("response is", response);
            })
            .catch((err) => {
                console.log("error is", err);
            });
    };

    return (
        <div
            className={`fixed top-0 right-0 sm:w-2/3 md:w-1/2 lg:w-1/4 h-full bg-white flex flex-col transition-transform duration-300  ${isCartOpen ? "translate-x-0" : "translate-x-full"}`}
        >
            {/* Header */}
            <div className="flex justify-between items-center bg-[#522f7c] p-6 h-24">
                <h2 className="text-xl font-bold text-white">Cart</h2>
                <div
                    className="cursor-pointer text-white text-xl md:text-2xl flex items-center"
                    onClick={minimize}
                >
                    <IoMdClose className="bg-white text-black" />
                </div>
            </div>

            {/* Cart Items (Scrollable) */}
            {totalItems > 0 ? (
                <div className="flex-1 overflow-y-auto p-4 bg-white">
                    <div className="flex flex-col text-lg font-semibold">
                        {cart.map((item) => (
                            <div key={item._id} className="m-2 flex justify-between items-center px-4 border-b-2 border-gray-300">
                                <div className="w-[80%]">
                                    <span>{item.name}</span>
                                </div>
                                <div>
                                    <span>{item.amount}</span>
                                </div>
                                <div className="cursor-pointer ps-4">
                                    <MdDeleteOutline
                                        onClick={() => removeFromCart(item._id)}
                                        className="text-purple-800 text-2xl"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="flex justify-center items-center h-full bg-gray-50">
                    <video
                        src={cartv}
                        className="lg:h-40 md:h-32 sm:h-24 h-24 lg:w-40 md:w-32 sm:w-20 w-20 object-contain bg-transparent rounded-lg"
                        autoPlay
                        loop
                        muted
                    ></video>
                </div>
            )}

            {/* Footer (Fixed at bottom) */}
            <div className="flex flex-col justify-end p-4 flex-shrink-0 bg-white">
                <div className="font-bold text-purple-800">
                    Total: <span className="font-bold text-purple-800">{totalPrice}</span>
                </div>
                <div className="border-t-2 border-black w-full pb-2"></div>

                <div className="flex flex-wrap justify-between gap-4 items-center">
                    <div>
                        <Link to="/finalPayment">
                            <button onClick={minimize} className="bg-green-500 rounded-lg p-2">
                                Checkout
                            </button>
                        </Link>
                    </div>
                    <div>
                        <button onClick={() => clearCart()} className="bg-yellow-500 rounded-lg p-2">
                            Clear Cart
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cart;
