import React from 'react';

const AddressList = ({ addresses = [], selectedAddress, handleAddressSelection, handleDeleteAddress, handleEditAddress }) => {

    return (
        <div>
            {addresses.length > 0 ? (
                addresses.map((add, index) => (
                    <div key={index} className="flex items-center space-x-4 mb-4 p-4 border-b bg-white">
                        <input
                            type="radio"
                            name="address"
                            value={index}
                            checked={selectedAddress === add}
                            onChange={() => handleAddressSelection(add)}
                            className="h-5 w-5"
                        />
                        <div>
                            <div><strong>{add.streetAddress}</strong></div>
                            <div>{add.city}, {add.state} {add.postalCode}</div>
                            <div>{add.country}</div>
                        </div>
                        <div className="flex items-center space-x-4">
                            <button
                                onClick={() => handleEditAddress(add)}  // Open address for editing
                                className="text-blue-500 hover:text-blue-700"
                            >
                                Edit
                            </button>
                            <button
                                onClick={() => handleDeleteAddress(index)}  // Delete the address
                                className="text-red-500 hover:text-red-700"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                ))
            ) : (
                <div>No address data available</div>
            )}
        </div>
    );
};

export default AddressList;
