import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GiHamburgerMenu } from "react-icons/gi";
import Cookies from 'js-cookie';
import Cart from '../pages/header/Cart';
import { useCart } from '../context/CartContext';
import { CgProfile } from "react-icons/cg";
import Search from './Search';
import logo from "../Images/logo.png";
import MobileView from './MobileView';

const Header = () => {
  const navigate = useNavigate()
  const cartItem = useCart()
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [profile, setProfile] = useState(false);
  const [toggleSignin, setToggleSignin] = useState(false)
  const [isCartOpen, setIsCartOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const popupSignin = () => {
    setToggleSignin(!toggleSignin);
  };

  const handleProfileHover = () => {
    setProfile(true);
  };
  const handleProfileLeave = () => {
    setProfile(false);
  };

  const handleLogout = () => {
    Cookies.remove('token')
    navigate("/")
  }

  const handleCart = () => {
    setIsCartOpen(!isCartOpen)
  }

  const handleHome = (() => {
    setIsProfileOpen(!isProfileOpen)
  })
  const token = Cookies.get('token');
  return (
    <nav className="lg:px-8 md:px-8 sm:px-2 px-4 bg-white  lg:h-16  md:h-20 sm:h-12 h-12 shadow-md flex items-center sticky top-0 z-10">
      <div className="w-full flex justify-between items-center">
        {/* Hamburger Icon for mobile */}
        <div className="block lg:hidden flex justify-between items-center w-full">
          <div onClick={toggleMenu}>
            <GiHamburgerMenu className="text-black text-2xl cursor-pointer" />
          </div>


          {/* Search Icon */}
          <div className='flex  items-center justify-between'>
            <div className="relative flex-grow max-w-md mx-4">
              <Search />
            </div>
            {/* Cart Icon */}
            <div onClick={handleCart} className="relative">
              <i className="fas fa-shopping-cart text-xl"></i>
              <span className="absolute top-[-8px] right-[-10px] bg-[#522f7c] text-white rounded-full px-2 py-1 text-xs">
                {cartItem.cart.length || 0}
              </span>
            </div>
          </div>
        </div>


        {/* Desktop Navigation Menu */}
        <div className="hidden lg:flex gap-16 items-center justify-between text-base w-full">
          <div>
            <Link to="/healthrisknavbarlink" className="text-black">
              <button className="group text-md font-medium relative cursor-pointer bg-none border-none outline-none">
                Health Risks
                {/* Underline animation */}
                <span className="absolute left-0 bottom-[-6px] h-[1px] w-0 bg-[#522f7c] group-hover:w-full transition-all duration-300"></span>
              </button>
            </Link>
          </div>

          <div>
            <Link to="/health-conditions" className="text-black">
              <button className="group text-md font-medium relative cursor-pointer bg-none border-none outline-none">
                Health Conditions
                {/* Underline animation */}
                <span className="absolute left-0 bottom-[-6px] h-[1px] w-0 bg-[#522f7c] group-hover:w-full transition-all duration-300"></span>
              </button>
            </Link>
          </div>

          <div>
            <Link to="/bloodtest" className="text-black">
              <button className="group text-md font-medium relative cursor-pointer bg-none border-none outline-none">
                Blood Tests
                {/* Underline animation */}
                <span className="absolute left-0 bottom-[-6px] h-[1px] w-0 bg-[#522f7c] group-hover:w-full transition-all duration-300"></span>
              </button>
            </Link>
          </div>

          <div>
            <Link to="/healthpackagecollection" className="text-black">
              <button className="group text-md font-medium relative cursor-pointer bg-none border-none outline-none">
                Truehealth Packages
                {/* Underline animation */}
                <span className="absolute left-0 bottom-[-6px] h-[1px] w-0 bg-[#522f7c] group-hover:w-full transition-all duration-300"></span>
              </button>
            </Link>
          </div>

          <div>
            <Link to="/specialty" className="text-black">
              <button className="group text-md font-medium relative cursor-pointer bg-none border-none outline-none">
                Specialty
                {/* Underline animation */}
                <span className="absolute left-0 bottom-[-6px] h-[1px] w-0 bg-[#522f7c] group-hover:w-full transition-all duration-300"></span>
              </button>
            </Link>
          </div>

          <div>
            <Link to="/my-appointment" className="text-black">
              <button className="group text-md font-medium relative cursor-pointer bg-none border-none outline-none">
                My Appointment
                {/* Underline animation */}
                <span className="absolute left-0 bottom-[-6px] h-[1px] w-0 bg-[#522f7c] group-hover:w-full transition-all duration-300"></span>
              </button>
            </Link>
          </div>

          {/* Conditional rendering for logged-in users */}
          {token ? (
            <div
              onMouseEnter={handleProfileHover}
              onMouseLeave={handleProfileLeave}
              className="relative flex items-center"
            >
              <div>
                <CgProfile className="text-3xl cursor-pointer" />
              </div>

              {profile && (
                <div className="absolute top-7 left-1/2 transform -translate-x-1/2 bg-opacity-50 flex justify-center items-center z-50 transition-all ease-in-out duration-300">
                  <div className="bg-gray-100 p-2 rounded-lg w-52 relative shadow-lg">
                    <div className="space-y-0 mt-4">
                      <div >
                        <Link to="/userprofile">
                          <h1 className="text-lg font-semibold">Dashboard</h1>
                        </Link>
                        <hr className="my-2 border-gray-400" />
                      </div>
                      <div>
                        <Link to="/userorder">
                          <h2 className="text-lg font-semibold">My Sample Tracking</h2>
                        </Link>
                        <hr className="my-2 border-gray-400" />
                      </div>

                      <div >
                        <button onClick={handleLogout} className="text-lg font-semibold">Logout</button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Link to="/loginform">
              <button className='bg-purple-700 text-white font-medium px-4 py-2 rounded-md hover:bg-purple-400 hover:text-black'>Sign In</button>
            </Link>
          )}



          <div className="flex items-center justify-between gap-8">
            <div onClick={handleCart} className="relative cursor-pointer">
              <i className="fas fa-shopping-cart text-2xl"></i>
              <span className="absolute top-[-8px] right-[-10px] bg-purple-500 text-black rounded-full px-2 py-1 text-xs">
                {cartItem.cart.length || 0}
              </span>
            </div>
          </div>


        </div>

        <MobileView
          isMenuOpen={isMenuOpen}
          toggleMenu={toggleMenu}
          token={token}
          handleLogout={handleLogout}
          isProfileOpen={isProfileOpen}
          handleHome={handleHome}
          popupSignin={popupSignin}
        />
      </div>

      <Cart isCartOpen={isCartOpen} setIsCartOpen={setIsCartOpen} />
    </nav>
  );
};

export default Header;
