import React, { useEffect, useState } from "react";

const Career = () => {
    const [formData, setFormData] = useState({
        name: "",
        age: "",
        mobile: "",
        email: "",
        jobCategory: "",
    });

    const jobCategories = ["Doctor", "Veterinary", "Nursing", "Lab Technician"];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Submitted:", formData);
        alert("Form Submitted Successfully!");
        // Add form submission logic (e.g., API call) here
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="flex justify-center items-center h-[80vh] bg-gray-100 w-full">
            <form
                onSubmit={handleSubmit}
                className="w-full max-w-lg bg-white shadow-md rounded-lg p-4"
            >
                <h2 className="text-2xl font-bold text-gray-800 mb-2 text-center">
                    Career Form
                </h2>

                {/* Name Input */}
                <div className="mb-2">
                    <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700 mb-1"
                    >
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter your name"
                        required
                    />
                </div>

                {/* Age Input */}
                <div className="mb-4">
                    <label
                        htmlFor="age"
                        className="block text-sm font-medium text-gray-700 mb-1"
                    >
                        Age
                    </label>
                    <input
                        type="number"
                        id="age"
                        name="age"
                        value={formData.age}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter your age"
                        required
                    />
                </div>

                {/* Mobile Number Input */}
                <div className="mb-4">
                    <label
                        htmlFor="mobile"
                        className="block text-sm font-medium text-gray-700 mb-1"
                    >
                        Mobile Number
                    </label>
                    <input
                        type="tel"
                        id="mobile"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter your mobile number"
                        required
                    />
                </div>

                {/* Email Input */}
                <div className="mb-4">
                    <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 mb-1"
                    >
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter your email"
                        required
                    />
                </div>

                {/* Job Category Dropdown */}
                <div className="mb-6">
                    <label
                        htmlFor="jobCategory"
                        className="block text-sm font-medium text-gray-700 mb-1"
                    >
                        Job Category
                    </label>
                    <select
                        id="jobCategory"
                        name="jobCategory"
                        value={formData.jobCategory}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    >
                        <option value="" disabled>
                            Select a category
                        </option>
                        {jobCategories.map((category, index) => (
                            <option key={index} value={category}>
                                {category}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Submit Button */}
                <button
                    type="submit"
                    className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 focus:outline-none"
                >
                    Submit
                </button>
            </form>
        </div>

    );
};

export default Career;
