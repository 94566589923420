import React, { useState, useEffect } from "react";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link, useNavigate } from "react-router-dom";
import HeaderLocation from "./HeaderLocation";
import logo from "../Images/logo.png";
import axios from "axios";
import constantApi from "../constantApi";
const Search = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        if (searchQuery) {
            fetchSearchResults(searchQuery);
        } else {
            setSearchResults([]);
            setShowDropdown(false);
        }
    }, [searchQuery]);

    // Fetch search results from the API
    const fetchSearchResults = async (query) => {
        setIsLoading(true);
        try {
            const response = await axios.get(
                `${constantApi.baseUrl}user/product/search?s=${searchQuery}`
            );
            setSearchResults(response.data.data);
        } catch (error) {
            console.error("Error fetching search results:", error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleTestClick = (test) => {
        navigate(`/${test.category}/${test.testId}`, { state: { test } });
        setShowDropdown(false);
        setSearchQuery('');
    };
    return (
        <div>
            <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search for Tests, Health Checkups"
                className="w-full px-4 lg:py-2 md:py-1 sm:py-0 py-0  rounded-e-sm text-gray-700 pl-10"
            />
            <i className="fas fa-search absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 hidden sm:inline"></i>
            {/* Dropdown showing search results */}
            {searchResults.length > 0 && (
                <div className="absolute top-full left-0 w-full bg-white shadow-lg rounded-b-xl z-50 max-h-60 overflow-auto">
                    {searchResults.map((test) => (
                        <div
                            key={test._id}
                            className="p-2 border-b cursor-pointer"
                            onClick={() => handleTestClick(test)}
                        >
                            <p className="text-black">{test.name}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default Search
