import React, { useState } from "react";
import { IoMdTime } from "react-icons/io";
import Cookies from "js-cookie";
import { RiChatFollowUpFill } from "react-icons/ri";
import { FaFilePrescription } from "react-icons/fa";
import { Link } from "react-router-dom";
const ConsultDoctor = () => {
    const [toggleSignin, setToggleSignin] = useState(false);

    const token = Cookies.get("token");

    const handleLoginSuccess = (newToken) => {
        Cookies.set("token", newToken);
        setToggleSignin(false);
    };

    return (
        <div className="bg-gray-50 py-10">
            <div className="max-w-7xl mx-auto px-4 md:px-6 lg:px-8 text-center">
                {/* Title Section */}
                <h1 className="text-2xl md:text-4xl font-bold text-gray-800 mb-2">
                    Online doctor consultation with qualified doctors
                </h1>
                <p className="text-gray-600 text-lg mb-8">Starting at ₹199</p>

                {/* Features */}
                <div className="flex justify-center items-center flex-wrap gap-8 mb-8">
                    <div className="flex items-center gap-2">
                        <IoMdTime className="text-3xl" />
                        <span className="text-gray-700 font-semibold">Talk within 30 min</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <RiChatFollowUpFill className="text-3xl" />
                        <span className="text-gray-700 font-semibold">
                            Free follow up for 3 days
                        </span>
                    </div>
                    <div className="flex items-center gap-2">
                        <FaFilePrescription className="text-3xl" />
                        <span className="text-gray-700 font-semibold">
                            Get a valid prescription
                        </span>
                    </div>
                </div>

                {/* Button */}
                <div className="mb-10">
                    {token ? (
                        <Link to="/choose-doctor">
                            <button className="bg-[#FF5E5E] text-white font-semibold px-6 py-3 rounded-md hover:bg-[#ff4a4a] transition">
                                Start new consultation
                            </button>
                        </Link>
                    ) : (
                        <Link to="/loginform">
                            <button
                                onClick={() => setToggleSignin(true)}
                                className="bg-[#FF5E5E] text-white font-semibold px-6 py-3 rounded-md hover:bg-[#ff4a4a] transition"
                            >
                                Consult now
                            </button>
                        </Link>

                    )}
                </div>

                {/* Stats */}
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 border-2 border-gray-300 p-12 justify-center items-center">
                    <div className="flex flex-col items-center gap-4 text-start border-r sm:border-r-2 border-gray-300">
                        <p className="text-6xl font-bold text-[#FF5E5E]">30L+</p>
                        <p className="text-gray-600 font-medium text-xl">Total Consultations</p>
                    </div>
                    <div className="flex flex-col items-center gap-4 text-start border-r sm:border-r-2 border-gray-300">
                        <p className="text-6xl font-bold text-[#FF5E5E]">3k+</p>
                        <p className="text-gray-600 font-medium text-xl">Daily Consultations</p>
                    </div>
                    <div className="flex flex-col items-center gap-4 text-start">
                        <p className="text-6xl font-bold text-[#FF5E5E]">22+</p>
                        <p className="text-gray-600 font-medium text-xl">Specialities</p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ConsultDoctor;
