import React from 'react';

import { showToast } from '../utils/toastUtils';
import ToastNotification from '../components/ToastNotification';

const Test = () => {
    return (
        <div>
            <ToastNotification
                position="top-center" // Center the toast horizontally at the top
                autoClose={5000} // Optional: Toast auto close time
                hideProgressBar={false} // Optional: Show the progress bar
                newestOnTop={false} // Optional: To ensure toasts stack in order
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastClassName="mt-40 bg-gray-800 text-white rounded-lg shadow-lg" // Add Tailwind class for margin-top and other styles
            />
            <button onClick={() => showToast("Your custom toast message!!!!")}>Show Toast</button>
        </div >
    );
};

export default Test;
