// FinalPayment.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCart } from '../../context/CartContext';
import constantApi from '../../constantApi';
import { Link, useNavigate } from 'react-router-dom';
import AddressModal from './AddressModal';
import AddressList from './AddressList';
import { MdDelete } from "react-icons/md";
import Cookies from 'js-cookie';
import LoginForm from './LoginForm';

const FinalPayment = () => {
    const [userDetail, setUserDetail] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const { cart, removeFromCart } = useCart();
    const [totalPrice, setTotalPrice] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [islogin, setIsLogin] = useState(false);
    const [editingAddressIndex, setEditingAddressIndex] = useState(null);
    const [userToken, setUserToken] = useState()

    const navigate = useNavigate();


    useEffect(() => {
        const token = Cookies.get('token');
        setUserToken(token)
        axios.get(`${constantApi.baseUrl}user/`, {
            headers: { "Authorization": token },
        })
            .then((response) => {
                setIsLogin(false)
                setUserDetail(response.data.data);
            })
            .catch((err) => {
                console.log("Error:", err);
            });
    }, []);

    useEffect(() => {
        const total = cart.reduce((accumulator, item) => accumulator + item.amount, 0);
        setTotalPrice(total);
    }, [cart]);

    const handleAddressSelection = (address) => {
        setSelectedAddress(address);
    };

    // Handle the opening of the modal for editing
    const handleEditAddress = (address) => {
        setIsEditMode(true);
        setSelectedAddress(address);
        setIsModalOpen(true);
    };

    // Handle deleting an address
    const handleDeleteAddress = (index) => {
        const updatedAddresses = [...userDetail.address];
        updatedAddresses.splice(index, 1);
        const updatedUserDetail = { ...userDetail, address: updatedAddresses };

        const token = localStorage.getItem('token');

        axios.put(`${constantApi.baseUrl}user/`, updatedUserDetail, {
            headers: { "Authorization": token },
        })
            .then(() => {
                setUserDetail(updatedUserDetail);
            })
            .catch((err) => {
                console.log("Error deleting address:", err);
            });
    };

    const productId = cart.map((item) => item._id)
    const totalamount = totalPrice
    const address = selectedAddress

    const obj = {
        productId, totalamount, address
    }

    const handlePayment = () => {
        // console.log("User Detail is ", obj);
        const token = Cookies.get('token');
        axios.post(
            `${constantApi.baseUrl}user/order`,
            obj,
            {
                headers: {
                    "Authorization": token,
                }
            }
        )
            .then((response) => {
                console.log("response is ", response);
            })
            .catch((err) => {
                console.log("error is", err);
            });
    };

    const handleAlert = () => {
        alert("please login first ")
        navigate("/loginform")
        setIsLogin(true)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="flex flex-col md:flex-row justify-between items-start m-4 rounded-lg shadow-lg">
            {/* Left Section */}
            <div className="w-full md:w-[70%] my-4 mx-2 px-2">
                <div className="border-2 border-gray-300 rounded-lg shadow-2xl">
                    <div className="py-4">
                        <h1 className="text-center font-bold text-xl">Packages & Tests in Your Cart</h1>
                    </div>
                    <div className="py-2">
                        <div className="overflow-x-auto">
                            <table className="table-auto w-full border-collapse border border-gray-300">
                                <thead className="bg-gray-200">
                                    <tr>
                                        <th className="border border-gray-300 px-4 py-2 text-left">S.No</th>
                                        <th className="border border-gray-300 px-4 py-2 text-left">Item Name</th>
                                        <th className="border border-gray-300 px-4 py-2 text-left">Price</th>
                                        <th className="border border-gray-300 px-4 py-2 text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cart.map((item, index) => (
                                        <tr key={item._id} className="hover:bg-gray-100">
                                            <td className="border border-gray-300 px-4 py-2">{index + 1}</td>
                                            <td className="border border-gray-300 px-4 py-2">{item.name}</td>
                                            <td className="border border-gray-300 px-4 py-2">₹{item.amount}</td>
                                            <td className="border border-gray-300 px-4 py-2 text-center">
                                                <button
                                                    onClick={() => removeFromCart(item._id)}
                                                    className="text-purple-800 text-2xl"
                                                >
                                                    <MdDelete />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

                <div className="bg-purple-800 h-12 my-8 text-white flex items-center justify-center">
                    <h1>Delivery Address</h1>
                </div>

                <div className="bg-gray-200 p-4 rounded-lg">
                    <div className="flex justify-center items-center pb-4">
                        {userToken && (
                            <button
                                onClick={() => setIsModalOpen(true)}
                                className="px-8 py-3 text-lg font-semibold text-white bg-green-600 rounded-md shadow-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600"
                            >
                                + Add Address
                            </button>
                        )}
                    </div>

                    <AddressList
                        addresses={userDetail?.address || []}
                        selectedAddress={selectedAddress}
                        handleAddressSelection={handleAddressSelection}
                        handleEditAddress={handleEditAddress}
                        handleDeleteAddress={handleDeleteAddress}
                    />
                </div>
            </div>

            {/* Right Section */}
            <div className="w-full md:w-[30%] my-4 mx-2 bg-white rounded-lg">
                <div className="border-2 border-purple-800 rounded-lg p-2 bg-white">
                    <div>
                        <span className="font-bold text-black text-2xl">Total Order</span>
                    </div>
                    <div className="m-2 flex justify-between items-center px-4 font-semibold text-xl border-b-2 border-purple-900 pb-4">
                        <div className="w-[80%]">
                            <p className="text-xl">Total MRP</p>
                            <p className="text-xl">Home visit charges</p>
                        </div>
                        <div>
                            <p>₹{totalPrice}</p>
                            <p>₹0</p>
                        </div>
                    </div>
                    <div className="m-2 flex justify-between items-center px-4 font-semibold text-xl pb-4">
                        <div className="w-[80%]">
                            <p className="font-bold text-xl">To Pay</p>
                        </div>
                        <div>
                            <p>₹{totalPrice}<span>.00</span></p>
                        </div>
                    </div>
                </div>

                <div className="flex justify-center w-full my-2 text-white font-bold">
                    {userToken ? (
                        <button onClick={handlePayment} className="bg-purple-900 p-3 w-full rounded-xl">Proceed to Payment</button>
                    ) : (
                        <button onClick={handleAlert} className="bg-purple-900 p-3 w-full rounded-xl">Proceed to Payment</button>
                    )}
                </div>
                {/* {islogin && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start pt-20">
                        <LoginForm />
                    </div>
                )} */}
            </div>

            {/* Address Modal */}
            <AddressModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                userDetail={userDetail}
                setUserDetail={setUserDetail}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
                selectedAddress={selectedAddress}
            />
        </div>
    );

};

export default FinalPayment;
