import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCart } from '../../context/CartContext';
import axios from 'axios';
import constantApi from '../../constantApi';
import Loader from '../Loader';
import { useLocationContext } from '../../context/LocationContext';

const HealthRiskCollection = () => {
    const { category } = useParams();
    const [tests, setTests] = useState([]);
    const [filteredTests, setFilteredTests] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const { addToCart, cartItemIds } = useCart();
    const { selectedLocation } = useLocationContext();

    // Fetch all tests by category
    useEffect(() => {
        setIsLoading(true);
        axios
            .get(`${constantApi.baseUrl}user/product/${category}`)
            .then((response) => {
                setTests(response.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching tests: ", error);
                setIsLoading(false);
            });
    }, [category]);

    // Filter tests based on selected location
    useEffect(() => {
        if (selectedLocation) {
            const filtered = tests.filter((test) =>
                test.location?.some((loc) =>
                    loc.toLowerCase().includes(selectedLocation.toLowerCase())
                )
            );
            setFilteredTests(filtered);
        } else {
            setFilteredTests(tests);
        }
    }, [selectedLocation, tests]);

    // Handle navigation to test details
    const handleViewDetails = (test) => {
        navigate(`/${category.toLowerCase().replace(/\s+/g, '-')}/${test._id}`, { state: { test } });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="pb-12 mt-8">
            <section className="px-4 sm:px-8">
                <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center sm:text-left">
                    {category}
                </h2>
                {isLoading ? (
                    <div className="flex justify-center items-center lg:h-80 md:h-60 sm:h-60 h-60">
                        <Loader />
                    </div>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                        {filteredTests.length > 0 ? (
                            filteredTests.map((test) => (
                                <div key={test._id} className="w-full">
                                    <div
                                        onClick={() => handleViewDetails(test)}
                                        className="border border-gray-300 rounded-lg p-6 shadow-md hover:shadow-lg transition-all duration-200 ease-in-out cursor-pointer flex flex-col items-center justify-center space-y-4"
                                    >
                                        <h3 className="text-lg font-semibold text-gray-700 text-center">
                                            {test.name}
                                        </h3>
                                        <p className="text-lg font-semibold text-black-600">
                                            Rs. {test.amount}
                                        </p>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                addToCart(test);
                                            }}
                                            className={`text-white py-2 px-6 text-sm rounded-md w-full ${cartItemIds.includes(test._id) ? "bg-green-600" : "bg-[#522f7c]"
                                                }`}
                                        >
                                            {cartItemIds.includes(test._id) ? "Added to Cart" : "Add to Cart"}
                                        </button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="col-span-full flex flex-col items-center justify-center space-y-4">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-16 h-16 text-gray-400"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M8.25 9a3.75 3.75 0 117.5 0v1.5M5.25 9V6.75a6.75 6.75 0 0113.5 0V9m-15 0a2.25 2.25 0 012.25-2.25h10.5A2.25 2.25 0 0120.25 9v8.25a6.75 6.75 0 01-6.75 6.75h-3.5A6.75 6.75 0 013.75 17.25V9m15 0h-15"
                                    />
                                </svg>
                                <p className="text-lg text-gray-500 text-center">
                                    No tests found for the selected location.
                                </p>
                            </div>
                        )}
                    </div>
                )}
            </section>
        </div>
    );
};

export default HealthRiskCollection;
