import axios from 'axios';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import constantApi from '../../constantApi';
import Cookies from 'js-cookie';

const UserSignInForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const userData = location.state || {};

    const [formData, setFormData] = useState({
        name: userData.name || '',
        phone: userData.phone || '',
        email: userData.email || '',
        dob: userData.dob || '',
        gender: userData.gender || '',
        whatsapp: userData.whatsapp || false,
    });

    const handleChange = (e) => {
        const { name, type, value, checked } = e.target;

        setFormData((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,  // Update boolean for checkbox
        }));
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // const token = localStorage.getItem("token");
        const token = Cookies.get("token");


        console.log("Updated user:", formData);

        axios
            .put(`${constantApi.baseUrl}user/`, formData, {
                headers: {
                    "Authorization": token,
                },
            })
            .then((response) => {
                console.log("Response after user update:", response);
                const updatedUserData = response.data.data;
                // setUserDetail(updatedUserData);
                navigate("/userprofile");
            })
            .catch((error) => {
                console.error('Error updating user details:', error);
            });
    };

    return (
        <div className="container mx-auto p-4 px-20">
            <h1 className="text-2xl font-bold text-green-500 mb-4 text-center">Edit Profile</h1>
            <form onSubmit={handleSubmit}>
                {/* Name input */}
                <div className="mb-4">
                    <label className="block text-gray-700">Name</label>
                    <input
                        type="text"
                        name="name"  // Field name to reference in formData
                        value={formData.name}
                        onChange={handleChange}  // Use generic handleChange
                        className="w-full p-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>

                {/* Phone input */}
                <div className="mb-4">
                    <label className="block text-gray-700">Phone Number</label>
                    <input
                        type="text"
                        name="phone"  // Field name to reference in formData
                        value={formData.phone}
                        onChange={handleChange}  // Use generic handleChange
                        className="w-full p-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>

                {/* Email input */}
                <div className="mb-4">
                    <label className="block text-gray-700">Email</label>
                    <input
                        type="email"
                        name="email"  // Field name to reference in formData
                        value={formData.email}
                        onChange={handleChange}  // Use generic handleChange
                        className="w-full p-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>

                {/* Date of birth input */}
                <div className="mb-4">
                    <label className="block text-gray-700">Date of Birth</label>
                    <input
                        type="date"
                        name="dob"  // Field name to reference in formData
                        value={formData.dob}
                        onChange={handleChange}  // Use generic handleChange
                        className="w-full p-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>

                {/* Gender select */}
                <div className="mb-4">
                    <label className="block text-gray-700">Gender</label>
                    <select
                        name="gender"  // Field name to reference in formData
                        value={formData.gender}
                        onChange={handleChange}  // Use generic handleChange
                        className="w-full p-2 border border-gray-300 rounded-md"
                        required
                    >
                        <option >Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                    </select>
                </div>

                {/* WhatsApp enable checkbox */}
                <div className="mb-4">
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            name="whatsapp"  // Field name to reference in formData
                            checked={formData.whatsapp}  // Bind checkbox to formData.whatsapp
                            onChange={handleChange}  // Use generic handleChange
                            className="w-6 h-6 border border-gray-300 rounded-md"
                        />
                        <label className="ml-2">Enable WhatsApp</label>
                    </div>
                </div>

                {/* Submit button */}
                <div className="mb-4 flex justify-center">
                    <button
                        type="submit"
                        className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-md"
                    >
                        Save Changes
                    </button>
                </div>
            </form>
        </div>
    );
};

export default UserSignInForm;
